.taskTable__name-container {
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;

  font-weight: 500;
}

.taskTable__name {
  width: 250px;
  list-style-type: none;
  margin: 10px;
  font-size: 14px;
  text-align: start;
  
}

.taskTable__text {
  width: 350px;
  list-style-type: none;
  margin: 10px;
  font-size: 14px;
  text-align: start;
}

.taskTable__status {
  width: 100px;
  list-style-type: none;
  font-size: 14px;
  margin: 10px;
  text-align: start;
}

.taskTable__status-date {
  width: 120px;
  list-style-type: none;
  font-size: 14px;
  margin: 10px;
  text-align: start;
  display: flex;
  justify-content: flex-start;
}

.pogination__num-container {
  padding: 0;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  flex-wrap: wrap;
}

@media screen and (max-width: 510px) {
  .pogination__num-container {
      margin-top: 25px;
      margin-bottom: 25px;
  }
}