.filterByQuantity-btn-container {
    display: flex;
    gap: 5px;
}

.filterByQuantity-btn {
    width: 30px;
    height: 30px;
    border: none;
    background-color: black;
    color: #fff;
    border-radius: 3px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.filterByQuantity-btn:hover {
    opacity: 0.7;
}

.filterByQuantity-main-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.filterByQuantity-title {
    font-size: 16px;
    margin-right: 10px;
}