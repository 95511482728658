.filter__popup {
    visibility: hidden;
    transition: 0.3s;
}

.filter__popup__opened {
    visibility: visible;
    display: flex;
    justify-content: end;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    z-index: 11;
    background-color: rgba(0, 0, 0, .3);
}

.filter__popup__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    padding-bottom: 25px;
    z-index: 10;
    position: fixed;
    top: 100px;
    right: 0;
    opacity: 1;
    box-sizing: border-box;
    min-width: 350px;
    background-color: #ffffff;
    border-radius: 3%;
}


.filter__popup_listcontainer {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 105px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 500px) {
    .filter__popup__listcontainer {
        margin-top: 99px;
    }
}

.filter__popup__list {
    text-align: center;
    list-style-type: none;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 28px;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s ease;
}

@media screen and (max-width: 500px) {
    .filter__popup__list {
        margin-bottom: 24px;
    }
}

.filter__popup__listlink {
    text-decoration: none;
    color: black;
}

.filter__popup__listlink:hover {
    text-underline-offset: 4px;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    opacity: 0.7;
}

@media screen and (max-width: 500px) {
    .filter__popup__listlink:hover {
        text-underline-offset: 7px;
    }
}

.filter__popup__closebtn {
    position: absolute;
    top: 22.4px;
    right: 22.4px;
    width: 25px;
    height: 25px;
    background-image: url(../../images/Group.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #ffffff;
    cursor: pointer;
    border: none;
    opacity: 1;
    transition: 0.3s ease;
    background-size: contain;
}

.filter__popup__closebtn:hover {
    opacity: 0.3;
    transition: 0.3s ease;
}

.filter__popup__select {
    width: 90%;
    height: 30px;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 12px;

}

.filter__popup__input {
    width: 90%;
    height: 30px;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 12px;
    padding-left: 5px;

}

/*
.filter__popup__input:last-of-type {
  margin-bottom: 30px;
}*/

.filter__popup__form {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
    width: 250px;
}

.filter__popup__filter-btn {
    padding: 5px;
    height: 30px;
    border-radius: 8px;
    background-color: black;
    color: #ffffff;
    font-size: 12px;
    border: none;
    width: fit-content;
    cursor: pointer;
    margin-top: 20px;
}

.filter__popup__filter-btn:hover {
    opacity: 0.7;
}

.filter__popup__inputname {
    font-size: 12px;
    margin-bottom: 5px;
    width: 90%;
    color: black;
    opacity: 0.7;
    text-align: start;
}

.unapprovePopup__container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
    width: 400px;
    height: 250px;
    border-radius: 8px;
    border: none;
}

.unapprovePopup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: visibility 1s, opacity 1s;
    box-sizing: border-box;
}

.unapprovePopup__opened {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
    z-index: 55;
}

.unapprovePopup__close-button {
    background-image: url(../../images/Group.png);
    width: 32px;
    height: 32px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 0.3s ease;
    position: absolute;
    top: 10px;
    right: 10px;
}

@media screen and (max-width: 600px) {
    .unapprovePopup__close-button {
        right: -30px;
        top: -10px;
    }
}

.unapprovePopup__close-button:hover {
    opacity: 60%;
    transition: 0.5s ease;
}


.unapprovePopup__button-container {
    display: flex;
    gap: 10px;
    margin: auto;
    margin-top: 20px;
}

.unapprovePopup__title {
    /*text-align: center;*/
    align-self: center;
    margin-top: 50px;
    max-width: 350px;
}

.deletePopup__title {
    text-align: center;
    margin-top: 50px;
    max-width: 80%;
    align-self: center;
}


.infoPopup__message {
    max-width: 350px;
}

.infoPopup__container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
    width: 400px;
    height: 250px;
    border-radius: 8px;
    border: none;
    justify-content: center;
    align-items: center;
    z-index: 555;
}

.filter__popup__filter-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter__popup__filter-btn-container button {
    padding: 2px 4px;
    font-size: 12px;
    font-weight: 600;
}

.filter__popup__input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.filter__popup__input-reset-btn {
    width: 15px;
    height: 15px;
    background-image: url(../../images/refresh.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 10px;
}

.filter__popup__input-reset-btn:hover {
    width: 17px;
    height: 17px;
}


