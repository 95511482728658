.tasks-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 14vw;
    border-left: 1px solid #fff;
    
}
.tasks-title {
    height: 50px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 1px solid #fff;
}
.tasks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}