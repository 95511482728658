.createTaskForm__form {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 600px;
    align-items: center;
    justify-content: center;
}
/*
.createTaskForm__input {
    height: 40px;
    border-radius: 8px;
    border: 1px solid rgb(219, 217, 217);
    width: 90%;
}
.createTaskForm__input_text {
    height: 400px;
    border-radius: 8px;
    border: 1px solid rgb(219, 217, 217);
    width: 90%;
}*/

.createTaskForm__button {
    width: 140px;
    height: 40px;
    border-radius: 8px;
    background-color: #f7e174;
    border: none;
    margin: 10px;
    font-size: 10px;
    cursor: pointer;
}

.createTaskForm__label {
    margin: 10px;
}

.createTaskForm-main-container {
    max-width: 1000px;
    margin: auto;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.createTaskForm-main-input {
    width: 300px;
    border-radius: 8px;
    border: 1px solid rgb(204, 55, 55);
    margin-left: 20px;
    padding: 8px 16px;
    color: #333333;
    font-weight: 600;
    letter-spacing: 0.02em;
    background-color: transparent;
    font-size: 16px;
    outline: none;
}


.createTaskForm-main-input_valid {
    border: 1px solid #333333;
}

.createTaskForm__select {
    padding: 8px 16px;
    border-radius: 8px;
    color: #333333;
    font-weight: 600;
    letter-spacing: 0.02em;
    background-color: transparent;
    width: 300px;
    font-size: 16px;
    border: 1px solid rgb(204, 55, 55);
    outline: none;
}

.createTaskForm__select option {
    color: #333333;
}

.createTaskForm__select_valid {
    border: 1px solid #333333;
}

.my-ckeditor-container img{
    z-index: 1;
}

.createTaskForm__form {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 70px;
    justify-content: center;
    align-items: center;
}

.createTaskForm-main-title {
    margin-top: 0;
    margin-bottom: 70px;
}

.createTaskForm__select-and-input-container {
    display: flex;
    margin-bottom: 25px;
}

.createTaskForm-main-btn {
    font-size: 16px;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 8px;
    border: #333333 solid 1px;
    background-color: transparent;
    color: #333333;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 25px;
    opacity: 0.4;
}

.createTaskForm-main-btn_active {
    opacity: 1;
    cursor: pointer;
}

.createTaskForm__err-span {
    color: rgb(204, 55, 55);
    margin-top: 15px;
    font-size: 12px;
}

.createTaskForm-main-input-container {
    display: flex;
    flex-direction: column;
}
