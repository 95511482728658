.pogination__item-btn {
    list-style-type: none;
    margin: 5px;

}
.pogination__btn {
    border: 1px solid #333333;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: 0.2s;
    background-color: transparent;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
}

.pogination__btn_active {
    background-color: #333333;
    color: #fff;
    cursor: default;
}

.pogination__btn:hover {
    background-color: black;
    transition: 0.5s ease;
}