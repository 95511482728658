.signInPage {
  display: flex;
  flex-direction: column;

}

.signInPage__back-up {
background-color: rgba(248,246,238,1);
height: 315px;
position: relative;
padding-top: 100px;
}

@media screen and (max-width: 800px) {
  .signInPage__back-up  {
      padding-top: 50px;
  }
}

.signInPage__back-up-wrapper {
display: flex;
justify-content: space-between; 
margin-left: 100px;
margin-right: 100px;
}

@media screen and (max-width: 1000px) {
  .signInPage__back-up-wrapper  {
      margin-left: 50px;
      margin-right: 50px;
  }
}

@media screen and (max-width: 600px) {
  .signInPage__back-up-wrapper  {
      margin-left: 15px;
      margin-right: 15px;
      flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .signInPage__back-up-wrapper  {
      margin-left: 5px;
      margin-right: 5px;
  }
}

.signInPage__pic {
  height: 315px;
      
  background-image: url(../../images/regpic.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;

  padding-bottom: 50px;
}

.signInPage__title {
  width: 50%;
  max-width: 350px;
  font-size: 40px;
  font-weight: 400;
  line-height: initial;
  direction: ltr;
  font-style: normal;
  color: #333333;
  font-family: Alice;
  margin-right: 30px;
}

@media screen and (max-width: 800px) {
  .signInPage__title  {
      font-size: 28px;
  }
}

@media screen and (max-width: 600px) {
  .signInPage__title  {
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-top: 0;
  }
}

.signInPage__component {
  width: 50%;
}

@media screen and (max-width: 600px) {
  .signInPage__component  {
      width: 100%;
  }
}