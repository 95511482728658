.loading__opened {
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    z-index: 21;
    background-color: rgba(0,0,0,.3);
}

.loading__title {
    color: #fff;
}