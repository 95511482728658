.searching__main-container {
    display: flex;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.searching__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
}

.searching__select {
    width: 200px;
    height: 30px;
    border-radius: 8px;
    border: 1px solid black;

    font-size: 10px;
}


.searching__select-container {
    display: flex;
    margin: 20px;
}

.searching__settings-btn {
    background-image: url(../../../images/setting.png);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: fill;
    border: none;
    cursor: pointer;
    
}

.searching__settings-btn:hover {
    opacity: 0.7;
}
.searching__settings-btn_container {
    position: relative;
    /*display: flex;*/
    /*align-items: center;*/
    /*gap: 10px;*/
}
.count_filters {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -15px;
    right: -15px;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background-color: #f8f0c7;
    font-weight: 700;
    color: #333333;
    border: #333333 solid 2px;
}
.clean_filters {
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-weight: 600;
    position: absolute;
    bottom: -20px;
    left: -20px;
}