.notfoundpage {
    display: flex;
    flex-direction: column;
  }
  
  .notfoundpage__title {
    font-size: 140px;
    font-weight: 400;
    margin: auto;
    text-align: center;
    margin-top: 200px;
    margin-bottom: 5;
  }
  
  @media screen and (max-width: 1000px) {
    .notfoundpage__title {
      font-size: 140px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .notfoundpage__title {
      font-size: 80px;
    }
  }
  
  .notfoundpage__subtitle {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-top: 0;
    margin-bottom: 184px;
  }
  
  @media screen and (max-width: 1000px) {
    .notfoundpage__subtitle {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .notfoundpage__subtitle {
      font-size: 12px;
    }
  }
  
  .notfoundpage__backlink {
    font-size: 14px;
    font-weight: 400;
    margin: auto;
    margin-top: 0;
    margin-bottom: 60px;
    color: #FF6838;
    text-decoration: none;
  }