.button {
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #333333;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.6px;
    letter-spacing: 0.02em;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.button:hover {
    background-color: #eee096;
    color: black;
}
.button_active {
    color: white;
    background-color: #333333;
}
