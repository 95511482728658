.signIn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .signIn__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    align-self: center;
    background-color: #fff;
    border-radius: 10px;
  }
 
  @media screen and (max-width: 600px) {
    .signIn__form  {
      width: 80%;
    }
  }

  @media screen and (max-width: 400px) {
    .signIn__form  {
      width: 90%;
    }
  }
  
  .signIn__fieldset {
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0;
    width: 80%;
  } 
  
  .signIn__input-span {
    margin: 12px;
    display: flex;
    color: rgba(1,53,55,1);
    font-family: 'DM Sans', sans-serif;
    font-weight: 300;
  }
  
  .signIn__input-span:first-of-type {
    margin-top: 30px;
  }
  
  .signIn__input {
    border: none;
    border-bottom: 1px solid rgba(226,205,193,1);
    width: 100%;
    height: 30px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .signIn__btn {
    max-width: 250px;
    min-width: 250px;
    height: 50px;
    border-radius: 8px;
    border: #333333 solid 1px;
    background-color: transparent;
    color: #333333;
    opacity: 0.7;
    font-weight: 600;
    font-family: 'Alice', serif;
    font-size: 16px;
  }

  @media screen and (max-width: 1000px) {
    .signIn__btn {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 750px) {
    .signIn__btn  {
      min-width: 200px;
    }
  }
  
  @media screen and (max-width: 600px) {
  .signIn__btn {
    font-size: 12px;
  }
  }
  
  @media screen and (max-width: 400px) {
    .signIn__btn {
      font-size: 10px;
    }
  }

  .signIn__btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 70px;
  }

  @media screen and (max-width: 800px) {
    .signIn__btn-container  {
        margin-bottom: 40px;
    }
  }

  .signIn__btn_active {
    cursor: pointer;
    opacity: 1;
  }
  
  @media screen and (max-width: 1000px) {
    .signIn__btn  {
        font-size: 14px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .signIn__btn  {
        font-size: 12px;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .signIn__btn_active  {
        font-size: 14px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .signIn__btn_active  {
        font-size: 12px;
    }
  }
  
  .contactForm__err-span {
    font-size: 10px;
    color: rgb(204, 55, 55);
    margin-top: 3px;
  }

  .signIn__under-btn-err-span {
    font-size: 12px;
    color: rgb(204, 55, 55);
    margin-bottom: 10px;
  }

  .signIn__title {
    margin-top: 70px;
    font-size: 40px;
    font-size: 40px;
    font-weight: 400;
    line-height: initial;
    direction: ltr;
    font-style: normal;
    color: #333333;
  }
  
  @media screen and (max-width: 800px) {
    .signIn__title  {
        font-size: 28px;
        margin-top: 40px;
    }
  }