.taskTable__section {
  margin-bottom: 25px;
}

.taskTable__name-container {
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-top: 25px;
}

.taskTable__name {
  width: 250px;
  list-style-type: none;
  font-size: 14px;
  margin: 10px;
  text-align: start;

}

.taskTable__text {
  width: 350px;
  list-style-type: none;
  margin: 10px;
  font-size: 14px;
  text-align: start;
}

.taskTable__status {
  width: 100px;
  list-style-type: none;
  
  margin: 10px;
  font-size: 14px;
  text-align: start;
}

.taskTable__message {
  margin-top: 100px;
}