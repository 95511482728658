.one-task__link-main-wrapper {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 90%;

    position: relative;

}



.one-task-container {
    list-style-type: none;
    margin: 10px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: start;
}

.one-task-container-name {
    list-style-type: none;
    width: 250px;
    display: flex;
    margin: 10px;
    align-items: start;
}

.one-task-container-text {
    list-style-type: none;
    width: 350px;
    display: flex;
    padding: 10px;
    align-items: start;
}

.one-task-container-text img {
    width: 0px;
    height: 0px;
}

.one-task-container-button {
    align-self: center;
}

.one-task__text {
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
    color: black;
    overflow: hidden;
    display: flex;
    align-items: start;
    text-align: start;
    max-width: 250px;
    overflow-wrap: break-all;
    overflow-wrap: break-word;
}

.one-task__text img {
    width: 0px;
    height: 0px;
}

@media screen and (max-width: 980px) {
    .one-task__text {
        max-width: 200px;
    }
  }

@media screen and (max-width: 880px) {
    .one-task__text {
        max-width: 170px;
    }
}  

.one-task__status-confirm {
    text-decoration: none;
    background-color: rgb(64, 175, 64);
    border-radius: 50%;
    color: #fff;
    height: 10px;
    width: 70px;
    padding: 1px;
    font-size: 8px;
    border-radius: 3px;
    position: absolute;
    top: -15px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.one-task__status-unconfirm {
    text-decoration: none;
    background-color: rgb(219, 111, 97);
    
    color: #fff;
    height: 10px;
    width: 70px;
    padding: 1px;
    font-size: 8px;
    border-radius: 3px;
    position: absolute;

    top: -15px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.one-task-container-number {
    list-style-type: none;
    margin: 10px;
    height: 60px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.one-task-container-data {
    list-style-type: none;
    margin: 10px;
    height: 60px;
    width: 120px;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    align-items: center;
}
.highlightClass {
    background-color: yellow;
    font-weight: bold;
}
.solution {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #333333;
    padding: 8px 16px;
    border-radius: 8px;
}