.tasks-page-full-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 25px;
    min-height: 70vh;

}

.tasks-page-kanban-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}
