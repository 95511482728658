.background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: black;
    opacity: 60%;
    z-index: 100;
}
.window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 40px;
    border-radius: 20px;
    min-width: 30vw;
    max-width: 80vw;
    text-align: start;
    z-index: 101;
    max-height: 65vh;
    overflow-y: auto;
}
.window img {
    max-width: 79vw;
    object-fit: cover;
    height: auto;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.close img {
    width: 15px;
    height: 15px;
}

.name {
    font-size: 18px;
    font-weight: 600;
}

.links {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #333333;
    padding-top: 10px;
}
.links a {
    color: black;
}