.one-task__link-main-wrapper {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 90%;
    position: relative;
}

.one-task-ul {
    padding: 0;
    /*height: 80px;*/
    background-color: #f8f0c7;
    margin-bottom: 10px;
    margin-top: 0;
    box-shadow: 0 3px 6px #999;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: start;
    
}    

.one-task-ul:hover {
    box-shadow: 0 6px 7px #999;
    background-color: #fcf0b4;
}



.one-task-container-text {
    list-style-type: none;
    width: 350px;
    display: flex;
    align-items: center;

    padding: 10px;
}

.one-task__text {
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
    color: black;
    overflow: hidden;
    text-align: start;
    object-fit: contain;
}


.one-task-btn-approve {
    border: 1px solid rgb(64, 175, 64);
    color: rgb(64, 175, 64);
    border-radius: 8px;
    cursor: pointer;
    height: 30px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
}

.one-task-btn-approve:hover {
    background-color: rgb(64, 175, 64);
    color: #fff;
}

.one-task-btn-unapprove {
    border: 1px solid rgb(78, 125, 211);
    color: rgb(78, 125, 211);
    border-radius: 8px;
    cursor: pointer;
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
}

.one-task-btn-unapprove:hover {
    background-color: rgb(78, 125, 211);
    color: #fff;
}

.one-task-container_button {
    align-items: center;
}
