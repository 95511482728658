.header {
    background-color: #f8f0c7; 
    height: auto;
    display: flex;
 
    position: sticky;
    top: 0;
    z-index: 2;
 
    transition: background-color 0.3s ease-in-out;
 }
 
 .header_scrolling {
     background-color: #fff;
     transition: background-color 0.3s ease-in-out;
 }
 
 .header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 100px;
    margin-right: 100px;
    width: 100%;
    height: 50px;
}
 
 @media screen and (max-width: 1280px) {
     .header__wrapper  {
         margin-left: 50px;
         margin-right: 50px;
     }
 }
 
 @media screen and (max-width: 600px) {
     .header__wrapper  {
         margin-left: 15px;
         margin-right: 15px;
     }
 }
 
 @media screen and (max-width: 400px) {
     .header__wrapper  {
         margin-left: 5px;
         margin-right: 5px;
     }
 }
 
 .header__logo {
    width: 70px;
    height: 70px;
    margin-right: 10px;
 }
 
 @media screen and (max-width: 600px) {
    .header__logo  {
        width: 150px;
        height: 40px;
     }
 }
 
.header__btn {
    width: 120px;
    height: 40px;
    border-radius: 8px;
    background-color: black;
    color: #fff;
    border: none;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

 .header__btn:hover {
    box-shadow: 0 3px 6px #999;
 } 
 
 @media screen and (max-width: 400px) {
     .header__btn  {
         width: 120px;
         height: 30px;
         font-size: 12px;
     }
 }
 
 .header__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
 }
 
 .header__greeting {
     margin: 0;
     margin-right: 30px;
     align-self: center;
     font-size: 16px;
     text-align: end;
 }
 
 @media screen and (max-width: 650px) {
     .header__greeting  {
         font-size: 12px;
     }
 }
 
 @media screen and (max-width: 550px) {
     .header__greeting  {
         display: none;
     }
 }

.header__link {
    color: black;
    text-decoration: none;
    margin: 10px;
    opacity: 0.7;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}

.header__link:hover {
    opacity: 1;
}

.header__link--active {
    border-bottom: #333333 solid 2px;
    opacity: 1;
}

.header__nav-container {
    display: flex;
    align-self: center;
}

.header__btn-and-greeting-container {
    display: flex;
}