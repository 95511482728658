.OneTaskPage__main-container {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 25px;
    margin-bottom: 25px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 60vh;
}
.OneTaskPage__title {
    max-width: 100%;
    text-align: start;
    font-weight: 600;
    border-bottom: 1px solid #999;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.OneTaskPage__info-container {
    display: flex;
    gap: 50px;
    margin-bottom: 20px;
}

.OneTaskPage__date {
    text-align: start;
    margin: 0;
}
.OneTaskPage__text {
    text-align: start;
}

.OneTaskPage__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.OneTaskPage__analyst-container {
    min-width: 250px;
    max-width: 25%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 3px 6px #999;
    height: fit-content;

    margin-bottom: 10px;
}


.OneTaskPage__analyst-text {
    margin-top: 0;
    font-weight: 600;
}

.OneTaskPage__container {
   padding: 10px;
   border-radius: 10px;
   box-shadow: 0 3px 6px #999;
   max-width: 70%;
   min-width: 70%;
    text-align: start;
}

.OneTaskPage__dangerous {
    object-fit: contain;
    max-width: 100%;
}

.OneTaskPage__dangerous img {
    max-width: 90%; 
    /*max-height: 100%;*/
    height: auto;
}

.OneTaskPage__analyst-and-owner-container {
    display: flex;
    flex-direction: column;
}

.OneTaskPage__date-container {
    display: flex;
}

.OneTaskPage__date-title {
    margin-top: 0;
    font-weight: 600;
    margin-right: 10px;
}

.OneTaskPage__info-container {
    display: flex;
}

.OneTaskPage__date-container-wrapper {
}

.OneTaskPage__date__unapproved {
    color: rgb(219, 111, 97);
}

.OneTaskPage__date__approved {
    color: rgb(64, 175, 64);
}
.OneTaskPage__top_buttons {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.OneTaskPage__column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}