.showFilter__btn-container {
    margin-left: 100px;
    margin-right: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.showFilter__container_search {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}
.showFilter__btn {
    height: 20px;
    width: 20px;
    border-radius: 3px;
    cursor: pointer;
    margin: 10px;
    
    border: 1px solid black;
    background-color: black;
    align-self: center;
}

.showFilter__label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.searching__input {
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #333333;
    color: #333333;
    font-weight: 600;
    line-height: 21.6px;
    letter-spacing: 0.02em;
    background-color: transparent;
    width: 300px;
    font-size: 16px;
}

.searching__input:focus {
    outline: none;
}

.searching__input::placeholder {
    font-size: 14px;
}


.input {
    display: flex;
    align-items: center;
}

.input input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.input label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.input .switch {
    text-indent: -9999px;
    width: 40px;
    height: 16px;
    background: #828EAD;
    display: block;
    border-radius: 100px;
    position: relative;
    margin-right: 10px;
}

.input .switch:after {
    content: "";
    position: absolute;
    border: #828EAD solid 1px;
    top: -2px;
    left: 0;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 20px;
    /*transition: 0.3s;*/
}

.input input:checked + label .switch {
    background: rgb(64, 175, 64);
}
.input input:checked + label .switch:after {
    border: rgb(64, 175, 64) solid 1px;
}

.input input:checked + label .switch:after {
    left: 100%;
    transform: translateX(-100%);
}

.input .switch:active:after {
    /*width: 100%;*/
}

