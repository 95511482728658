.task-container {
    border-radius: 8px;
    list-style-type: none;
    /*width: 200px;*/
    width: 13vw;
    border: 1px solid #fff;
    cursor: pointer;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px #999;
}

.task-container:hover{ 
    box-shadow: 0 5px 9px #999;
}

.task-status-container {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.task-status-confirm {
    background-color: rgb(64, 175, 64);
    border-radius: 3px;
    color: #fff;
    padding: 3px;
    padding-left: 3px;
    padding-right: 3px;
    margin: 0;
    font-size: 10px;
}

.task-status-no-confirm {
    background-color: rgb(219, 111, 97);
    border-radius: 3px;
    color: #fff;
    margin: 0;
    padding: 2px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 10px;
}

.task-title {
    
    border-bottom: 1px solid rgb(167, 164, 164);
    font-weight: 400;
    font-size: 12px;
    height: 20px;
    width: 100%;
    align-self: center;
    text-align: start;
    
    margin: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}



.task-title-container {
    height: 20px;
    width: 100%;
    
    display: flex;
    
    justify-content: center;
    align-items: center;
    align-self: center;
    
}


.task-text-container {
    max-height: 150px;
    width: 100%;
    overflow-x: auto;
    box-sizing: border-box;
    overflow: hidden;
}

.task-text {
    font-weight: 300;
    font-size: 12px;
    margin: 10px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    color: #313131;
    height: 100px;
    overflow: hidden;
    min-height: 10px;
    text-align: start;

}


.task-link {
    text-decoration: none;
    color: black;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background-color: #f8f0c7;
}

.task-number {
    font-size: 10px;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}
